<template>
  <div>
    <titleLink
      title="奖补名单"
      :onClick="onClickBack"
      style="height: 1rem"
    ></titleLink>
    <div class="body">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="refreshList"
        style="padding-bottom: 2rem"
      >
        <van-list
          v-model="listLoading"
          :finished="listFinished"
          :finished-text="list.length > 0 ? '没有更多了' : ''"
          @load="loadList(0)"
        >
          <div>
            <card
              v-for="(item, idx) in list"
              :key="idx"
              style="margin-top: 0.2rem"
            >
              <template #content>
                <van-row style="line-height: 15px">
                  <van-col span="18">
                    <div @click="onMessageClicktwo(item)">
                      <span class="list-title">{{ item.userable_name }}</span>
                    </div>
                  </van-col>
                  <van-col span="6">
                    <div>
                      <span class="list-but">{{ item.status_text }}</span>
                    </div>
                  </van-col>
                </van-row>
              </template>
            </card>
          </div>
        </van-list>
        <van-empty :description="'没有名单'" v-if="list.length === 0" />
      </van-pull-refresh>
    </div>
  </div>
</template>
  
<script>
import { Toast } from "vant";
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink";
import api from "@/services/apis";
export default {
  components: {
    titleLink,
    card,
  },
  data() {
    return {
      ids: "",
      list: [],
      isLoading: false,
      listLoading: false,
      type: 0, // 0: travel_list 1: emergency_report 2: tourists
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null,
      },
      jiang_bu_id: "",
    };
  },
  mounted() {
    this.jiang_bu_id = this.$route.query.id;
    this.refreshList();
  },
  computed: {
    listFinished() {
      return this.paging.next_page === null;
    },
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    refreshList() {
      this.isLoading = true;
      let params = {
        page: 1,
        // status: ["pending_approve"],
        // status: "approve_pass",
        jiang_bu_id: this.jiang_bu_id,
        status: "approve_pass",
      };
      // api.get_applyjiangbu_list().then(res => {
      api.get_jiang_bu_list(params).then((res) => {
        this.isLoading = false;
        console.log("可申请列表", res.data);
        if (res.data.status === 200) {
          this.list = res.data.data;
          this.syncPaging(res.data);
        }
      });
    },
    loadList() {
      this.loadList = true;
      let params = {
        page: this.paging.next_page,
        status: ["pending_approve"],
      };
      // api.get_applyjiangbu_list(params).then(res => {
      api.get_jiangbu_list(params).then((res) => {
        this.listLoading = false;
        console.log("可申请列表2", res.data);
        if (res.data.code === 200) {
          res.data.data.forEach((ele) => {
            this.list.push(ele);
          });
          this.syncPaging(res.data);
        }
      });
    },
    syncPaging(res) {
      this.paging.total_count = res.page.total_count;
      this.paging.total_pages = res.page.total_pages;
      this.paging.next_page = res.page.next_page;
      this.paging.current_page = res.page.current_page;
    },
    // 申请奖补
    applyJiangbu(e) {
      api.apply_jiangbu(e.jiang_bu.id).then((res) => {
        console.log("可申请列表", res.data);
        if (res.data.code === 200) {
          this.refreshList();
          Toast("申请成功！");
        } else {
          Toast(res.data.message);
        }
      });
    },
    // 奖补详情
    onMessageClicktwo(item) {
      console.log(item);
      // this.$router.push({path: `/xiaoxi/jiangbuinfo`, query: {id: item.jiang_bu.id}});
    },
  },
};
</script>
  
<style scoped>
.body {
  padding: 0 0.2rem 3rem 0.2rem;
  height: 90vh;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.list-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
}

.list-but {
  font-size: 12px;
  color: #323334;
  font-weight: bold;
  line-height: 15px;
}

.list-item {
  font-size: 12px;
  color: #323334;
}

.right {
  text-align: right;
}
</style>